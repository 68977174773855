/*--------------------------------------------------------------
  18. Timeline
----------------------------------------------------------------*/
.cs-time_line.cs-style1 {
  h3 {
    font-size: 30px;
    margin-bottom: 15px;
  }
  h2 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 7px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.cs-time_line.cs-style1+.cs-time_line.cs-style1 {
  margin-top: 40px;
}
@media screen and (max-width: 1380px) {
  .cs-time_line.cs-style1 h3 {
    font-size: 26px;
  }
  .cs-time_line.cs-style1 h2 {
    font-size: 20px;
  }
}